import { Box, Button, Card, CardContent, Grid2 as Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import { UserHeader } from './components/UserHeader';

export const ProfileContainer: React.FC = () => {
  const { user, updateUser } = useUserContext();

  const venmoUsername = user?.venmoUsername;
  const [newUsername, setNewUsername] = useState(venmoUsername);

  const handleSaveChanges = () => {
    updateUser({ venmoUsername: newUsername || undefined });
  };

  useEffect(() => {
    setNewUsername(venmoUsername);
  }, [venmoUsername]);

  if (!user) {
    return null;
  }

  return (
    <Box sx={{ p: 2 }}>
      <UserHeader showButtons={false} />
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 8 }}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Profile Settings
              </Typography>
              <Box
                sx={{
                  color: 'text.secondary',
                  fontStyle: 'italic',
                }}
              >
                <Typography variant="body2">
                  To update your name, email, or profile image, click your avatar in the top right corner.
                </Typography>
              </Box>
              <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                  fullWidth
                  label="My Venmo Username"
                  value={newUsername || ''}
                  onChange={(e) => setNewUsername(e.target.value)}
                  sx={{ mt: 4, mb: 1 }}
                  InputProps={{
                    startAdornment: <Typography variant="body1">@</Typography>,
                  }}
                  helperText="Your Venmo username will be used to receive Split It payments from other users."
                />
                <Button variant="contained" color="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid size={{ xs: 12, md: 4 }}>
          <MySplitTokensHeader showBuyButton={false} />
          <MySplitTokenOrders />
        </Grid>*/}
      </Grid>
    </Box>
  );
};
