import {
  Add,
  CalendarToday as CalendarIcon,
  CheckCircle,
  People as PeopleIcon,
  Restaurant as RestaurantIcon,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useMyChecksContext } from '../../../hooks/useMyChecksContext';
import { FULLY_CLAIMED_COLOR } from '../../../theme';
import { $ } from '../../../util/formatCurrency.util';

interface MySplitsProps {
  type: 'all' | 'recent';
}

export const MyChecks: React.FC<MySplitsProps> = ({ type }) => {
  const limit = type === 'all' ? undefined : 5;
  const title = type === 'all' ? '🧾 My Splits' : '🧾 My Recent Splits';
  const buttonType = type === 'all' ? 'create-new' : 'view-all';

  const { myChecks } = useMyChecksContext();
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ pb: 2 }}>
          <Typography variant="h6">{title}</Typography>
          {buttonType === 'create-new' && (
            <Button variant="contained" color="primary" startIcon={<Add />} component={Link} to="/new-check">
              Create New
            </Button>
          )}
          {buttonType === 'view-all' && (
            <Button component={Link} to="/checks" variant="outlined">
              View All
            </Button>
          )}
        </Box>
        {myChecks.length === 0 && (
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <Typography variant="h5" gutterBottom>
              🍽️
            </Typography>
            <Typography variant="body1" color="text.secondary" align="center">
              You don't have any Splits yet. Start by creating a new bill!
            </Typography>
          </Box>
        )}
        <List disablePadding>
          {myChecks.slice(0, limit).map((check) => (
            <React.Fragment key={check.id}>
              <Divider component="li" />
              <ListItem disablePadding>
                <ListItemButton component={Link} to={`/checks/${check.id}`}>
                  {!isMobile && (
                    <ListItemIcon>
                      <RestaurantIcon color="primary" />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primaryTypographyProps={{ component: 'div' }}
                    secondaryTypographyProps={{ component: 'div' }}
                    primary={
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Box>
                          <Typography variant="subtitle1" component="span">
                            {check.restaurantName}
                          </Typography>
                          {check.isOwner ? (
                            <Typography
                              component="span"
                              variant="caption"
                              sx={{
                                ml: 1,
                                px: 1,
                                py: 0.5,
                                bgcolor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                                borderRadius: 1,
                              }}
                            >
                              My Bill
                            </Typography>
                          ) : (
                            <>
                              <Typography
                                component="span"
                                variant="caption"
                                sx={{
                                  ml: 1,
                                  color: theme.palette.text.secondary,
                                }}
                              >
                                •
                              </Typography>
                              <Typography
                                component="span"
                                variant="caption"
                                sx={{
                                  ml: 1,
                                  color: theme.palette.text.secondary,
                                }}
                              >
                                {check.ownerName}'s Bill
                              </Typography>
                            </>
                          )}
                        </Box>
                        <Typography variant="subtitle1" fontWeight="bold" color="primary">
                          {$(check.totalPrice)}
                        </Typography>
                      </Box>
                    }
                    secondary={
                      <>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="body2" color="text.secondary">
                            <CalendarIcon
                              sx={{
                                fontSize: 'small',
                                mr: 0.5,
                                verticalAlign: 'text-bottom',
                                color: theme.palette.primary.light,
                              }}
                            />
                            {check.formattedDate}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            <PeopleIcon
                              sx={{
                                fontSize: 'small',
                                mr: 0.5,
                                verticalAlign: 'text-bottom',
                                color: theme.palette.primary.light,
                              }}
                            />
                            {check.splitCount} people
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" mt={1}>
                          <Box flexGrow={1} mr={2}>
                            <LinearProgress
                              variant="determinate"
                              value={check.percentClaimed}
                              sx={{
                                height: 4,
                                borderRadius: 2,
                                backgroundColor: theme.palette.grey[200],
                                '& .MuiLinearProgress-bar': {
                                  backgroundColor:
                                    check.percentClaimed === 100 ? FULLY_CLAIMED_COLOR : theme.palette.secondary.main,
                                },
                              }}
                            />
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2">{check.percentClaimed}% claimed</Typography>
                            {check.percentClaimed === 100 && (
                              <CheckCircle
                                sx={{
                                  color: FULLY_CLAIMED_COLOR,
                                  fontSize: 'small',
                                  ml: 0.5,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
