import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ApiRequest } from '../common/api-request.type';
import { SplitTokenOrderDTO } from '../models/dto/SplitTokenOrder.dto';
import { SplitTokenOrder } from '../models/SplitTokenOrder.model';
import { SplitTokens } from '../models/SplitTokens.model';
import { useApiCall } from './useApiCall';
import { useAuthContext } from './useAuthContext';

interface MySplitTokensContextType {
  mySplitTokens: SplitTokens;
  mySplitTokenOrders: SplitTokenOrder[];
}

const MySplitTokensContext = createContext<MySplitTokensContextType | undefined>(undefined);

export const MySplitTokensProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const callApi = useApiCall();
  const { isSignedIn } = useAuthContext();

  const [mySplitTokens, setMySplitTokens] = useState<SplitTokens>(SplitTokens.empty());
  const [mySplitTokenOrders, setMySplitTokenOrders] = useState<SplitTokenOrder[]>([]);

  useEffect(() => {
    (async () => {
      if (!isSignedIn) return;

      const data = await callApi({
        request: ApiRequest.GetMySplitTokens,
        endpoint: '/tokens',
        method: 'GET',
        body: null,
      });

      setMySplitTokens(new SplitTokens(data));
    })();
  }, [isSignedIn]);

  useEffect(() => {
    (async () => {
      if (!isSignedIn) return;

      const data = await callApi({
        request: ApiRequest.GetMySplitTokens,
        endpoint: '/tokens/orders',
        method: 'GET',
        body: null,
      });

      setMySplitTokenOrders(data.map((dto: SplitTokenOrderDTO) => new SplitTokenOrder(dto)));
    })();
  }, [isSignedIn]);

  return (
    <MySplitTokensContext.Provider
      value={{
        mySplitTokens,
        mySplitTokenOrders,
      }}
    >
      {children}
    </MySplitTokensContext.Provider>
  );
};

export const useMySplitTokensContext = () => {
  const context = useContext(MySplitTokensContext);
  if (context === undefined) {
    throw new Error('useMySplitTokensContext must be used within a MySplitTokensProvider');
  }
  return context;
};
