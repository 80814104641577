import { DateTime } from 'luxon';
import { SplitTokenDTO, SplitTokensDTO } from './dto/SplitToken.dto';

export class SplitTokens {
  totalTokens: number;
  usedTokens: number;
  availableTokens: number;
  tokens: SplitToken[];

  constructor(dto: SplitTokensDTO) {
    this.totalTokens = dto?.total_tokens ?? 0;
    this.usedTokens = dto?.used_tokens ?? 0;
    this.availableTokens = dto?.available_tokens ?? 0;
    this.tokens = dto?.tokens.map((token) => new SplitToken(token)) ?? [];
  }

  static empty() {
    return new SplitTokens({
      total_tokens: 0,
      used_tokens: 0,
      available_tokens: 0,
      tokens: [],
    });
  }
}

export class SplitToken {
  id: number;
  createdAt: DateTime;
  checkId: number;
  checkRestaurantName: string;

  constructor(dto: SplitTokenDTO) {
    this.id = dto.id;
    this.createdAt = DateTime.fromISO(dto.created_at);
    this.checkId = dto.check_id;
    this.checkRestaurantName = dto.check_restaurant_name;
  }

  get isUsed(): boolean {
    return !!this.checkId;
  }

  get source(): string {
    return 'Free Beta SplitCoin';
  }
}
