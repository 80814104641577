import { Group, PersonAdd } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Chip, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCheckApi } from '../../hooks/useCheckApi';
import { useUserContext } from '../../hooks/useUserContext';
import { Check } from '../../models/Check.model';
import { theme } from '../../theme';
import { CheckHelmet } from '../helmet/CheckHelmet';

export const JoinCheckContainer: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shareCode = searchParams.get('share_code') || '';

  const { user } = useUserContext();

  const [nickname, setNickname] = useState('');
  const [check, setCheck] = useState<Check | null>(null);

  const { fetchCheckByShareCode, joinCheck } = useCheckApi();

  const handleJoinCheck = async (event?: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    const result = await joinCheck(shareCode, nickname);
    navigate(result ? `/checks/${result.checkId}?auth_code=${result.authCode}` : '/profile');
  };

  // Logged-in User should join without even asking
  useEffect(() => {
    if (user?.id && shareCode) {
      handleJoinCheck();
    }
  }, [user, shareCode]);

  useEffect(() => {
    const getCheck = async () => {
      if (!shareCode) {
        return;
      }

      const fetchedCheck = await fetchCheckByShareCode(shareCode);
      if (!fetchedCheck) {
        navigate('/dashboard');
        return;
      }

      setCheck(fetchedCheck);
    };
    getCheck();
  }, [shareCode]);

  if (!check) return null;

  const alreadySplitting = check.splits.filter((s) => s.isClaimed).map((s) => s.name.trim());

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: theme.palette.background.default,
        p: 2,
      }}
    >
      <CheckHelmet check={check} />
      <Card sx={{ maxWidth: 400, width: '100%' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">
            Split the bill for {check?.restaurant?.name}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom align="center">
            {check.owner?.firstName} has invited you to split the bill. Join now to claim your items and settle up
            easily!
          </Typography>
          <Box sx={{ p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
            <Typography variant="body1" color="text.secondary" gutterBottom align="center" fontWeight="bold">
              How it works:
            </Typography>
            <Stack spacing={1} alignItems="center">
              <Typography variant="body2" color="text.secondary">
                1. Enter your name and join the check 🤝
              </Typography>
              <Typography variant="body2" color="text.secondary">
                2. Claim the items you ordered 🍔
              </Typography>
              <Typography variant="body2" color="text.secondary">
                3. Review your total and pay {check.owner?.firstName} 💰
              </Typography>
            </Stack>
          </Box>
          <form onSubmit={handleJoinCheck}>
            <TextField
              fullWidth
              margin="normal"
              label="Your Name"
              variant="outlined"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<PersonAdd />}
              sx={{ mt: 2 }}
              disabled={!nickname.trim()}
            >
              Join bill to Split It
            </Button>
          </form>
          {alreadySplitting.length > 0 && (
            <Box sx={{ mt: 3 }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                align="center"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Group sx={{ mr: 1 }} />
                Already splitting:
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="center" flexWrap="wrap">
                {alreadySplitting.map((person, index) => (
                  <Box key={index} sx={{ my: 0.5, py: 0.5 }}>
                    <Chip label={person} color="primary" variant="outlined" />
                  </Box>
                ))}
              </Stack>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
