import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { useAlertContext } from '../../context/useAlertContext';

export const AlertContainer: React.FC = () => {
  const { alert, setAlert } = useAlertContext();

  if (!alert) {
    return null;
  }

  return (
    <Snackbar
      open={!!alert}
      autoHideDuration={5000}
      onClose={() => setAlert(null)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{
        '& .MuiAlert-root': {
          fontSize: '1rem',
          padding: '12px 20px',
        },
      }}
      style={{ top: '20px' }}
    >
      <Alert severity={alert?.type} variant="filled" sx={{ width: '100%' }}>
        {/* <AlertTitle>Error</AlertTitle> */}
        {alert?.message}
      </Alert>
    </Snackbar>
  );
};
