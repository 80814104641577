import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useAlertContext } from '../../../context/useAlertContext';
import { useCheckApi } from '../../../hooks/useCheckApi';
import { Check, CheckItem } from '../../../models/Check.model';
import { Split } from '../../../models/Split.model';
import { SplitQuantity } from '../../../models/SplitQuantity.model';

interface CheckContextType {
  check: Check | null;
  claimCheckItemQuantity: (quantity: SplitQuantity) => Promise<void>;

  selectedItem: CheckItem | null;
  setSelectedItem: (item: CheckItem | null) => void;
  selectedSplit: Split | null;
  setSelectedSplit: (split: Split | null) => void;
}

const CheckContext = createContext<CheckContextType | undefined>(undefined);

export const CheckProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { checkId } = useParams<{ checkId?: string }>();
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('auth_code');

  const { setSuccess } = useAlertContext();

  const { fetchCheck, claimCheckItems } = useCheckApi();

  const [check, setCheck] = useState<Check | null>(null);
  const [selectedItem, setSelectedItem] = React.useState<CheckItem | null>(null);
  const [selectedSplit, setSelectedSplit] = React.useState<Split | null>(null);

  useEffect(() => {
    const getCheck = async () => {
      if (checkId) {
        const fetchedCheck = await fetchCheck(checkId, authCode || undefined);
        if (!fetchedCheck) return;

        setCheck(fetchedCheck);
      }
    };
    getCheck();
  }, [checkId, authCode]);

  const claimCheckItemQuantity = async (quantity: SplitQuantity) => {
    if (!checkId || !selectedItem) return;

    const item = {
      item_id: selectedItem.id,
      ...quantity.toApiInput(),
    };
    const claimedCheck = await claimCheckItems(checkId, [item], authCode ?? undefined);
    if (!claimedCheck) return;

    setSuccess(`Claimed ${quantity.toString()} ${selectedItem.name}`);
    setCheck(claimedCheck);
    setSelectedItem(claimedCheck.items.find((i) => i.id === selectedItem.id) || null);
  };

  return (
    <CheckContext.Provider
      value={{
        check,
        claimCheckItemQuantity,
        selectedItem,
        setSelectedItem,
        selectedSplit,
        setSelectedSplit,
      }}
    >
      {children}
    </CheckContext.Provider>
  );
};

export const useCheckContext = () => {
  const context = useContext(CheckContext);
  if (context === undefined) {
    throw new Error('useCheckContext must be used within a CheckProvider');
  }
  return context;
};
