import { AttachMoney, Calculate, CameraAlt, QrCode } from '@mui/icons-material';
import { Avatar, Box, Card, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';

const howItWorks = [
  {
    icon: <CameraAlt />,
    title: 'Scan Receipt with AI',
    description: 'Our AI instantly reads and pre-populates your bill items.',
  },
  {
    icon: <QrCode />,
    title: 'Share QR Code',
    description: 'Friends can join the Split right at the table.',
  },
  {
    icon: <Calculate />,
    title: 'Claim Your Items',
    description: 'Everyone claims what they had. Tax, tip, and service fees are split automatically.',
  },
  {
    icon: <AttachMoney />,
    title: 'Get Paid Back Instantly',
    description: 'Receive payments immediately via one-tap Venmo payments.',
  },
];

export const HowItWorksSection: React.FC = () => {
  return (
    <Box sx={{ my: 8 }} id="how-it-works">
      <Typography variant="h3" color="primary" gutterBottom align="center">
        How Split It Works
      </Typography>
      <Typography variant="h5" color="textSecondary" sx={{ mb: 2 }} align="center">
        Split your bill in 4 easy steps: Scan, Share, Claim, and Get Paid
      </Typography>
      <Grid container spacing={4}>
        {howItWorks.map((step, index) => (
          <Grid size={{ xs: 12, sm: 6, md: 3 }} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
              <Box sx={{ position: 'relative', mb: 2 }}>
                <Avatar sx={{ bgcolor: 'primary.main', width: 60, height: 60 }}>{step.icon}</Avatar>
                <Avatar
                  sx={{
                    position: 'absolute',
                    top: -10,
                    left: -10,
                    width: 30,
                    height: 30,
                    bgcolor: 'success.main',
                    color: 'inherit',
                    fontSize: '0.875rem',
                    fontWeight: 'bold',
                  }}
                >
                  {index + 1}
                </Avatar>
              </Box>
              <Typography variant="h6" gutterBottom align="center">
                {step.title}
              </Typography>
              <Typography align="center">{step.description}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
