import { Box, Button, Chip, List, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { FULLY_CLAIMED_COLOR } from '../../../theme';
import { $ } from '../../../util/formatCurrency.util';
import { SplitCoinImage } from '../../common/SplitCoins';

export const BuySplitTokensCard: React.FC = () => {
  const tokenOptions = [
    { tokens: 1, price: 2 },
    { tokens: 2, price: 3 },
    { tokens: 5, price: 5, bestValue: true },
  ];

  return (
    <List>
      {tokenOptions.map((tokenBundle) => (
        <React.Fragment key={tokenBundle.tokens}>
          <ListItem
            key={tokenBundle.tokens}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1,
              ...(tokenBundle.bestValue && {
                bgcolor: FULLY_CLAIMED_COLOR, // Light green background for best value
                borderRadius: 1,
              }),
            }}
          >
            <ListItemText
              primary={
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1">
                    {tokenBundle.tokens} SplitCoin{tokenBundle.tokens > 1 ? 's' : '\u00A0\u00A0'} –
                    <strong> ${tokenBundle.price}</strong>
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ ml: 1, fontSize: '0.75rem' }}>
                    {$(tokenBundle.price / tokenBundle.tokens)} each
                    {tokenBundle.bestValue && <Chip label="Best Value" color="secondary" size="small" sx={{ ml: 1 }} />}
                  </Typography>
                </Box>
              }
            />
            <Button
              variant={tokenBundle.bestValue ? 'contained' : 'outlined'}
              color="primary"
              component={Link}
              to={`/tokens/buy?count=${tokenBundle.tokens}`}
              sx={{ minWidth: '100px', height: '40px', fontSize: '0.875rem' }}
            >
              Buy {tokenBundle.tokens} <SplitCoinImage />
            </Button>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};
