import { Edit as EditIcon, ReceiptLong } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useMySplitTokensContext } from '../../hooks/useMySplitTokensContext';
import { NewCheckForm } from './components/NewCheckForm';
import { ReceiptUpload } from './components/ReceiptUpload';

export interface NewCheckDataItem {
  item_name: string;
  total_price: number;
  quantity: number;
}

export interface NewCheckData {
  restaurant_name: string;
  tax: number;
  tip: number;
  service_fee: number;
  items: NewCheckDataItem[];
}

const EMPTY_CHECK_DATA: NewCheckData = {
  restaurant_name: '',
  tax: 0,
  tip: 0,
  service_fee: 0,
  items: [],
};

export const NewCheckContainer: React.FC = () => {
  const { mySplitTokens } = useMySplitTokensContext();

  const [splitCount, setSplitCount] = useState<number | null>(null);
  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [receiptCheckData, setReceiptCheckData] = useState<NewCheckData | null>(null);

  const onCheckDataParsed = (checkData: NewCheckData) => {
    setReceiptCheckData(checkData);
    setIsAIGenerated(true);
  };

  const onSelectManualEntry = () => {
    setReceiptCheckData(EMPTY_CHECK_DATA);
  };

  // if (!mySplitTokens) {
  //   return null;
  // }

  // mySplitTokens.availableTokens = 5;
  // const hasTokens = mySplitTokens.availableTokens > 0;

  // if (!hasTokens) {
  //   return (
  //     <Box sx={{ p: 2 }}>
  //       <Card sx={{ mb: 1 }}>
  //         <CardContent>
  //           <Typography variant="h5">New Split</Typography>
  //           <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
  //             You need SplitCoins to create and manage splits with your friends. Purchase SplitCoins to get started!
  //           </Typography>
  //         </CardContent>
  //       </Card>
  //       <MySplitTokensHeader showBuyButton={!hasTokens} showViewOrdersButton={false} />
  //     </Box>
  //   );
  // }

  const currentStep = !splitCount ? 0 : !receiptCheckData ? 1 : 2;

  const steps = ['Enter people', 'Add receipt', 'Create Split'];

  return (
    <Box sx={{ p: 2 }}>
      {/* <MySplitTokensHeader showBuyButton={!hasTokens} showViewOrdersButton={false} /> */}
      <Card sx={{ mb: 1 }}>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <Typography variant="h5">New Split</Typography>
            {/* <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                fontSize: '0.875rem', // Slightly smaller text
              }}
            >
              <SplitCoinImage /> {mySplitTokens.availableTokens} available • <SplitCoinImage />1 per split
            </Box> */}
          </Box>

          <Divider sx={{ mb: 2 }} />
          <Stepper activeStep={currentStep} alternativeLabel sx={{ mt: 2 }}>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </CardContent>
      </Card>
      {currentStep >= 0 && (
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <TextField
              label="How many people are splitting the bill?"
              type="number"
              fullWidth
              value={splitCount || ''}
              onChange={(e) => setSplitCount(+e.target.value)}
              InputProps={{
                inputProps: { min: 1, inputMode: 'numeric', pattern: '[0-9]*' },
              }}
              variant="outlined"
              autoFocus
              helperText="This will help you divide the bill accurately and claim individual items easily."
            />
          </CardContent>
        </Card>
      )}
      {currentStep === 1 && (
        <Card sx={{ mb: 1 }}>
          <CardContent>
            <ReceiptUpload onCheckDataParsed={onCheckDataParsed} />
            {!receiptCheckData && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => onSelectManualEntry()}
                fullWidth
                sx={{ mt: 2 }}
              >
                Or Enter Bill Details Manually
              </Button>
            )}
          </CardContent>
        </Card>
      )}
      {isAIGenerated && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{
            my: 1,
            border: '2px solid #4caf50',
            borderRadius: '4px',
            padding: '12px',
            backgroundColor: '#e8f5e9',
          }}
        >
          <ReceiptLong sx={{ fontSize: 36, color: '#4caf50', marginRight: '16px' }} />
          <Typography variant="subtitle1" color="#4caf50">
            Receipt successfully uploaded ✓
          </Typography>
        </Box>
      )}
      {currentStep >= 2 && (
        <NewCheckForm initialCheckData={receiptCheckData!} isAIGenerated={isAIGenerated!} splitCount={splitCount!} />
      )}
    </Box>
  );
};
