import { Box } from '@mui/material';
import React from 'react';

interface SplitCoinsProps {
  singular?: boolean;
  count?: number;
  number?: string;
  outlined?: boolean;
  prefix?: string;
}

export const SplitCoins: React.FC<SplitCoinsProps> = ({
  singular = false,
  count,
  number,
  outlined = true,
  prefix = '',
}) => {
  const isPlural = count ? count > 1 : !singular;

  return (
    <Box
      component="span"
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        ...(outlined && {
          bgcolor: 'background.paper',
          border: 1,
          borderColor: 'grey.300',
          borderRadius: 3,
        }),
        px: 1,
        py: 0.5,
      }}
    >
      {prefix}
      {number ? `${number} ` : count !== undefined && count !== null ? `${count} ` : ''}
      SplitCoin
      {isPlural ? 's' : ''}
      <SplitCoinImage />
    </Box>
  );
};

export const SplitCoinImage: React.FC = () => {
  return (
    <Box component="img" src="/splitit-logo.png" alt="SplitCoin" sx={{ ml: 0.5, width: '1.5em', height: '1.5em' }} />
  );
};
