import { Restaurant as RestaurantIcon } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMySplitTokensContext } from '../../../hooks/useMySplitTokensContext';
import { SplitToken } from '../../../models/SplitTokens.model';
import { DATE_FORMAT } from '../../../util/date.util';
import { $ } from '../../../util/formatCurrency.util';

export const MySplitTokenOrders: React.FC = () => {
  const { mySplitTokens, mySplitTokenOrders } = useMySplitTokensContext();

  const usedTokens = mySplitTokens.tokens.filter((token) => token.isUsed);

  return (
    <>
      {mySplitTokenOrders.length > 0 && (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Recent Purchases
            </Typography>
            <List>
              {mySplitTokenOrders.map((order) => (
                <>
                  <Divider />
                  <ListItem>
                    <ListItemText
                      primary={
                        <Box display="flex" alignItems="center">
                          <Typography component="span">{`${order.tokenQuantity} SplitCoins`}</Typography>
                          {order.totalPaid === 0 && <Chip size="small" label="Free" color="success" sx={{ ml: 1 }} />}
                        </Box>
                      }
                      secondary={`${order.totalPaid === 0 ? 'Added' : 'Purchased'} on ${order.createdAt.toFormat(
                        DATE_FORMAT,
                      )} • ${order.tokens.availableTokens}/${order.tokenQuantity} remaining`}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {order.totalPaid === 0 ? 'Free' : $(order.totalPaid)}
                    </Typography>
                  </ListItem>
                </>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
      {/* <Card sx={{ mt: 1 }}>
        <CardContent>
          <Typography variant="h6">Recently Used SplitCoins</Typography>
          {usedTokens.length === 0 && (
            <Box display="flex" flexDirection="column" alignItems="center" py={4}>
              <Typography variant="h5" gutterBottom>
                🍽️
              </Typography>
              <Typography variant="body1" color="text.secondary" align="center">
                You have not used any SplitCoins yet. Start by creating a new bill!
              </Typography>
            </Box>
          )}
          <List disablePadding>
            {usedTokens.map((token) => (
              <TokenListItem key={token.id} token={token} />
            ))}
          </List>
        </CardContent>
      </Card> */}
    </>
  );
};

const TokenListItem: React.FC<{ token: SplitToken }> = ({ token }) => {
  return (
    <React.Fragment key={token.id}>
      <Divider component="li" />
      <ListItem disablePadding>
        <ListItemButton component={Link} to={`/checks/${token.checkId}`}>
          <ListItemIcon>
            <RestaurantIcon color="primary" />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{ component: 'div' }}
            secondaryTypographyProps={{ component: 'div' }}
            primary={
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="subtitle1" component="span">
                    {token.checkRestaurantName}
                  </Typography>
                </Box>
              </Box>
            }
            secondary={
              <Typography variant="body2" color="text.secondary">
                Purchased on: {token.createdAt.toFormat(DATE_FORMAT)}
                <Chip label={token.source} size="small" color="secondary" sx={{ ml: 1 }} />
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
    </React.Fragment>
  );
};
