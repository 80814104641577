import { SignedIn, SignedOut, SignInButton, SignUpButton, UserButton } from '@clerk/clerk-react';
import { AppBar, Avatar, Box, Button, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export const NavbarContainer: React.FC = () => {
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Box
          component={Link}
          to="/"
          sx={{
            mr: 2,
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            flexGrow: 1,
            color: 'inherit', // This keeps the link color as white (inheriting from parent)
          }}
        >
          <Avatar sx={{ width: 40, height: 40, p: 0.5 }} alt="Split It Logo" src="/splitit-logo.png" />
          <Typography
            variant="h6"
            sx={{
              textDecoration: 'none',
              fontWeight: 700,
              letterSpacing: '.05rem',
              color: 'inherit',
            }}
          >
            Split It
          </Typography>
        </Box>
        <SignedOut>
          <SignUpButton forceRedirectUrl={window.location.href} mode="modal">
            <Button color="inherit">Sign Up</Button>
          </SignUpButton>
          <SignInButton forceRedirectUrl={window.location.href} mode="modal">
            <Button color="inherit">Sign In</Button>
          </SignInButton>
        </SignedOut>
        <SignedIn>
          <Button color="inherit" component={Link} to="/dashboard" sx={{ mr: 1 }}>
            Dashboard
          </Button>
          <Button color="inherit" component={Link} to="/checks" sx={{ mr: 2 }}>
            My Splits
          </Button>
          <UserButton />
        </SignedIn>
      </Toolbar>
    </AppBar>
  );
};
