import { ReceiptLong } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material';
import React from 'react';
import { useCheckContext } from '../hooks/useCheckContext';
import { ReceiptRow } from './ReceiptRow';

export const SplitModal: React.FC = () => {
  const { selectedSplit: split, setSelectedSplit } = useCheckContext();

  if (!split) return null;

  const onClose = () => setSelectedSplit(null);

  return (
    <Dialog open={!!split} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ pb: 0, display: 'flex', alignItems: 'center' }}>
        <ReceiptLong sx={{ mr: 1 }} />
        {split.name}
      </DialogTitle>
      <DialogContent sx={{ px: 2, py: 2 }}>
        {split.items.length > 0 && <Divider sx={{ my: 1 }} />}
        <Box sx={{ mb: 2 }}>
          {split.items.map((item) => (
            <ReceiptRow key={item.id} title={`${item.name} (${item.quantity})`} amount={item.splitPrice} />
          ))}
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ my: 1 }}>
          <ReceiptRow title="Subtotal" amount={split.subtotal} isBold />
          <ReceiptRow title="Tax" amount={split.tax} />
          <ReceiptRow title="Tip" amount={split.tip} />
          {!!split.serviceFee && <ReceiptRow title="Service Fee" amount={split.serviceFee} />}
        </Box>
        <Divider sx={{ my: 1 }} />
        <ReceiptRow title="Total" amount={split.totalPrice} isBold />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" sx={{ minWidth: '100px' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
