import { CssBaseline, ThemeProvider } from '@mui/material';
import React from 'react';
import { AppRouter } from './Router';
import { theme } from './theme';

export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </React.StrictMode>
  );
};
