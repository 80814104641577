import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { SplitCoins } from '../../common/SplitCoins';

export const FinalCTASection: React.FC = () => {
  return (
    <Box sx={{ my: 8, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Join Hundreds of Friends Splitting Bills Fairly
      </Typography>
      <Typography variant="h6" gutterBottom>
        Start splitting bills the easy way today!
      </Typography>
      <Button variant="contained" color="primary" size="large" component={Link} to="/dashboard">
        Start Splitting for Free
      </Button>
      <Typography variant="body2" sx={{ mt: 2 }}>
        No credit card required. Get 3 free <SplitCoins /> when you sign up!
      </Typography>
    </Box>
  );
};
