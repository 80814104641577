import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';

export const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: '#F5F7FA',
        py: 2,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 1 }}
          >
            Thank you for using Split It!
          </Typography>
          <Link
            href="mailto:support@splitit.dev"
            underline="none"
            sx={{
              color: 'text.secondary',
              fontSize: '0.875rem',
              '&:hover': {
                color: '#2D3436',
              },
            }}
          >
            support@splitit.dev
          </Link>
        </Box>
      </Container>
    </Box>
  );
};
