import { Avatar, Box, Button, Typography } from '@mui/material';
import React from 'react';
import { SplitCoins } from '../../common/SplitCoins';
import { Link } from 'react-router-dom';

export const HeroSection: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <Avatar sx={{ width: 100, height: 100, mt: 2, mb: 1 }} alt="Split It Logo" src="/splitit-logo.png" />
      <Typography variant="h2" color="primary" sx={{ mb: 2 }}>
        Split It
      </Typography>
      <Typography variant="h4" color="textSecondary" gutterBottom>
        The Fastest Way to Split Your Bill
      </Typography>
      <Typography variant="h6" color="primary" paragraph>
        Scan, split, and get paid back instantly. No more waiting, no more confusion.
      </Typography>
      <Button variant="contained" color="primary" size="large" sx={{ mt: 2 }} component={Link} to="/dashboard">
        Start Splitting for Free
      </Button>
      <Typography variant="body2" sx={{ mt: 2 }}>
        No credit card required. Get 3 free <SplitCoins /> when you sign up!
      </Typography>
    </Box>
  );
};
