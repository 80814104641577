import { Box, Button, Card, CardContent, Chip, Grid2 as Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { SplitCoins } from '../../common/SplitCoins';

interface PricingTierProps {
  tokens: number;
  price: number;
  perTokenPrice?: string;
  isPopular?: boolean;
  isFree?: boolean;
}

const PricingTier: React.FC<PricingTierProps> = ({ tokens, price, perTokenPrice, isPopular, isFree }) => (
  <Grid size={{ xs: 12, sm: 6, md: 3 }}>
    <Card
      sx={{
        height: '100%',
        bgcolor: 'white',
        border: isPopular ? '1px solid' : undefined,
        borderColor: 'divider',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
        borderRadius: 2,
        position: 'relative',
      }}
    >
      <CardContent sx={{ p: 3, textAlign: 'center' }}>
        <Box sx={{ height: '30px' }}>
          {isPopular && (
            <Chip
              label="Most Popular"
              sx={{
                bgcolor: '#2D3436',
                color: 'white',
                height: '24px',
                mt: -2,
                '& .MuiChip-label': {
                  px: 1,
                  fontSize: '0.75rem',
                  fontWeight: 500,
                },
              }}
            />
          )}
        </Box>

        <Typography
          variant="h3"
          sx={{
            fontSize: '2.5rem',
            fontWeight: 500,
            mb: 2,
            color: '#2D3436',
          }}
        >
          {isFree ? 'Free' : `$${price}`}
        </Typography>

        <Box sx={{ mb: 1.5 }}>
          <SplitCoins count={tokens} />
        </Box>

        <Typography
          sx={{
            color: 'text.secondary',
            mb: 2,
            fontSize: '0.875rem',
          }}
        >
          {isFree ? 'Try Split It with no commitment' : `$${perTokenPrice} per SplitCoin`}
        </Typography>

        <Button
          variant={isPopular ? 'contained' : 'outlined'}
          fullWidth
          sx={{
            textTransform: 'none',
            py: 1.5,
            bgcolor: isPopular ? '#2D3436' : 'transparent',
            borderColor: '#2D3436',
            color: isPopular ? 'white' : '#2D3436',
            '&:hover': {
              bgcolor: isPopular ? '#1a1f20' : 'rgba(45, 52, 54, 0.04)',
              borderColor: '#2D3436',
            },
            fontWeight: 400,
            fontSize: '0.875rem',
          }}
          component={Link}
          href={`/tokens/buy?count=${tokens}`}
        >
          {isFree ? 'Get Started' : `Buy ${tokens} SplitCoin${tokens !== 1 ? 's' : ''}`}
        </Button>
      </CardContent>
    </Card>
  </Grid>
);

export const PricingSection: React.FC = () => {
  return (
    <Box sx={{ bgcolor: '#F5F7FA', py: 4, px: 3 }} id="pricing">
      <Box sx={{ maxWidth: 1200, mx: 'auto' }}>
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: '2.5rem',
              fontWeight: 500,
              mb: 2,
              color: '#2D3436',
            }}
          >
            Pricing
          </Typography>
          <Typography variant="h6" sx={{ mb: 2 }}>
            One <SplitCoins singular /> For Any Bill
          </Typography>
          <Typography
            sx={{
              fontSize: '1.25rem',
              color: 'text.secondary',
              mb: 2,
              maxWidth: 800,
              mx: 'auto',
            }}
          >
            No matter the group size or bill amount, split any check with a single SplitCoin.
          </Typography>
          <Typography
            sx={{
              color: 'text.secondary',
              mb: 2,
              maxWidth: 800,
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            Buy SplitCoins whenever you need them and share the cost with friends.
            <br />
            You'll get paid back instantly through Venmo.
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          <PricingTier tokens={3} price={0} isFree={true} />
          <PricingTier tokens={1} price={2} perTokenPrice="2.00" />
          <PricingTier tokens={2} price={3} perTokenPrice="1.50" />
          <PricingTier tokens={5} price={5} perTokenPrice="1.00" isPopular={true} />
        </Grid>

        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography
            sx={{
              color: 'text.secondary',
              fontSize: '0.875rem',
              fontStyle: 'italic',
            }}
          >
            Pro tip: Split the cost of a <SplitCoins singular /> on a bill to make it even cheaper!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
